.button {
  appearance: none;
  border-radius: var(--radius);
  border: 0;
  cursor: pointer;
  margin: 0;
  transition: var(--transition);
  transition-property: background-color;
  font-size: 14px;
  line-height: 26px;
  font-weight: 700;
  text-transform: uppercase;
  display: flex;
  justify-content: center;
  gap: 8px;
}

/* Sizes */
.buttonM {
  padding: 8px 24px 8px 24px;
}

.buttonS {
  padding: 4px 16px 4px 16px;
  font-size: 12px;
  line-height: 22px;
}

/* Types */
.buttonDanger {
  background: var(--c-red);
  color: var(--c-on-color);
  font-weight: var(--bold);
}
.buttonDanger:hover {
  background: var(--c-red-secondary);
}

.buttonDisabled {
  cursor: auto;
  color: var(--text2);
  border-radius: 5px;
  border: 1px solid var(--surface4);
  background-color: var(--surface3);
}

.buttonPrimary {
  color: white;
  border-radius: 6px;
  border: 1px solid var(--brand2);
  background-color: var(--brand);
}

.buttonPrimary:hover {
  background-color: var(--brand2);
  border: 1px solid var(--brand);
  cursor: pointer;
}

.buttonPrimary:focus {
  background-color: var(--brand2);
  border: 1px solid var(--brand);
  cursor: pointer;
}

.buttonSecondary {
  background: transparent;
  color: var(--brand2);
  background-color: var(--surface2);
  border: 1px solid var(--surface3);
}

.buttonSecondary:hover {
  color: var(--brand2);
  background-color: var(--surface2);
  border: 1px solid var(--brand2);
  /* border: 1px solid var(--brand); */
}

.buttonSecondary:focus {
  color: var(--brand2);
  background-color: var(--surface2);
  border: 1px solid var(--brand);
}

.buttonWarning {
  background: transparent;
  color: var(--warning);
  border: 1px solid var(--surface3);
  background-color: var(--surface2);
}
.buttonWarning:hover {
  background-color: var(--surface2);
  color: var(--warning-hover);
  border: 1px solid var(--warning-hover);
}

.buttonWarning:focus {
  background-color: var(--surface2);
  color: var(--warning);
  border: 1px solid var(--warning);
}

/* Modifiers */
.buttonAlignLeading {
  text-align: left;
}

.buttonFullWidth {
  box-sizing: border-box;
  width: 100%;
}
