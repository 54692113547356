.contentWrapperSlim {
}

.alignCenter {
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.temp {
  color: var(--text-for-translate);
}
.temp:hover {
  cursor: pointer;
  text-decoration: underline;
}

.contentWrapperWide {
  height: 100%;
  width: 100%;
  display: grid;
  grid-template-columns: 2fr 1fr;
  justify-content: center;
  gap: 32px;
  padding: 0px 0px 0px 32px;
  /* align-items: center; */
}

.editorScroll {
  height: calc(100dvh - 48px);
  max-width: 760px;
  min-width: 600px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  overflow-y: scroll;
  padding-right: 32px;
  padding-bottom: 50%;
}

.editorNoScroll {
  height: max-content;
  max-width: 760px;
  min-width: 600px;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding-right: 0px;
  padding-bottom: 50%;
}

.contentNoScroll {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.contentScroll {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: scroll;
}

.wrapperNoScroll {
  height: 100%;
  max-width: 1240px;
  display: flex;
  flex-direction: row;
  gap: 48px;
  padding: 0px 32px;
}

.wrapperScroll {
  height: max-content;
  max-width: 760px;
  display: flex;
  flex-direction: row;
  padding: 0px 32px;
}

.h1 {
  color: var(--text1);
  font-size: 32px;
  line-height: 40px;
  font-family: Georgia;
  font-weight: 400;
  margin-top: 32px;
}

.firstaLang {
  color: var(--text-yellow);
}

.firstaLang:hover {
  cursor: pointer;
  border-bottom: 1px dotted var(--text-yellow);
}

@media only screen and (max-width: 1360px) {
}

@media only screen and (max-width: 1100px) {
  .editorNoScroll {
    overflow-y: scroll;
    padding-right: 8px;
    padding-bottom: 70%;
  }
}

@media only screen and (max-width: 664px) {
  .editorNoScroll {
    min-width: 320px;
    padding: 0px 16px;
    padding-bottom: 50%;
    gap: 8px;
    height: calc(100dvh - 98px);
  }
}

.app {
  height: 100dvh;
  width: 100vw;
  display: flex;
  flex-direction: row;
  overflow-y: hidden;
}

.section {
  height: 100dvh;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.contentNoScroll {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.contentScroll {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: scroll;
}

.wrapperNoScroll {
  height: 100%;
  max-width: 1240px;
  display: flex;
  flex-direction: row;
  gap: 48px;
  padding: 0px 32px;
}

.wrapperScroll {
  height: max-content;
  max-width: 1240px;
  display: flex;
  flex-direction: row;
  padding: 0px 32px;
}

.showOptionPlaceholder {
  min-width: 400px;
  max-width: 780px;
}

.hideOptionPlaceholder {
  display: none;
}

.scroll {
  overflow-y: scroll;
}

@media only screen and (max-width: 1550px) {
  .wrapperNoScroll {
    padding: 0px 16px 0px 32px;
  }
}

@media only screen and (max-width: 1360px) {
  .wrapperNoScroll {
    gap: 0px;
  }
}

@media only screen and (max-width: 1100px) {
  .wrapperScroll {
    gap: 0px;
    padding: 0px 44px;
  }

  .content {
    height: 100%;
  }

  .optionPlaceholder {
    min-width: 1px;
    max-width: 1px;
    min-height: 1px;
    max-height: 1px;
  }
}

@media only screen and (max-width: 664px) {
  .app {
    overflow-y: hidden !important;
  }

  .wrapperScroll {
    gap: 0px;
    padding: 0px 0px;
  }

  .content {
    overflow-y: hidden !important;
    height: calc(100dvh - 0px);

    /* padding-bottom: 50%; */
  }
}
