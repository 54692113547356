.editorScroll {
  height: calc(100dvh - 48px);
  max-width: 760px;
  min-width: 600px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  overflow-y: scroll;
  padding-right: 12px;
  padding-bottom: 50%;
  margin-right: 20px;
  width: 100%;
}

.editorNoScroll {
  gap: 16px;
  height: max-content;
  max-width: 760px;
  min-width: 600px;
  width: 100%;
  display: flex;
  flex-direction: column;
  padding-right: 12px;
  padding-bottom: 50%;
  margin-right: 20px;
}

.alignCenter {
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100%;
}

/* old */

@media only screen and (max-width: 1360px) {
}

@media only screen and (max-width: 1100px) {
  .editorNoScroll {
    overflow-y: scroll;
    padding-right: 8px;
    padding-bottom: 70%;
  }
}

@media only screen and (max-width: 790px) {
  .editorNoScroll {
    min-width: 320px;
    padding: 0px 16px;
    padding-bottom: 50%;
    gap: 8px;
    height: calc(100dvh - 98px);
    margin-right: 0px;
  }
}
