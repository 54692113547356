.wrapper {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: row;
}

.contentNoScroll {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: top;
}

.contentScroll {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: top;
  overflow-y: scroll;
}

.contentWrapperSlim {
  width: 100%;
}

.contentWrapperWide {
  height: 100%;
  width: 100%;
  display: grid;
  grid-template-columns: 2fr 1fr;
  justify-content: center;
  padding: 0px 0px 0px 32px;
}

@media only screen and (max-width: 1360px) {
  .contentWrapperWide {
    height: 100%;
    width: 100%;
    display: flex;
    /* grid-template-columns: 2fr 1fr; */
    justify-content: center;
    padding: 0px 0px 0px 32px;
  }
}

@media only screen and (max-width: 876px) {
  .contentWrapperWide {
    height: 100%;
    width: 100%;
    display: flex;
    /* grid-template-columns: 2fr 1fr; */
    justify-content: center;
    padding: 0px;
  }
}
