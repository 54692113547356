.wrapper {
  display: flex;
  flex-direction: row;
  gap: 8px;
  justify-content: center;
  align-items: center;
  height: 100dvh;
  width: 100vw;
  font-size: 18px;
  position: fixed;
  z-index: 9999999999;
  background-color: var(--surface1);
  color: var(--text1);
}

.spiner {
  color: var(--text1);
}
