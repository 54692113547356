.app {
  height: 100dvh;
  width: 100vw;
  display: flex;
  flex-direction: row;
  overflow-y: hidden;
}

.section {
  height: 100dvh;
  width: 100%;
  display: flex;
  flex-direction: column;
  color: var(--text2);
}

.contentNoScroll {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.contentScroll {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: scroll;
}

.wrapperNoScroll {
  height: 100%;
  max-width: 1240px;
  display: flex;
  flex-direction: row;
  gap: 48px;
  padding: 0px 32px;
}

.wrapperScroll {
  height: max-content;
  max-width: 1240px;
  display: flex;
  flex-direction: row;
  padding: 0px 32px;
}

.showOptionPlaceholder {
  min-width: 400px;
  max-width: 780px;
}

.hideOptionPlaceholder {
  display: none;
}

.scroll {
  overflow-y: scroll;
}

@media only screen and (max-width: 1550px) {
  .wrapperNoScroll {
    padding: 0px 16px 0px 32px;
  }
}

@media only screen and (max-width: 1360px) {
  .wrapperNoScroll {
    gap: 0px;
  }
}

@media only screen and (max-width: 1100px) {
  .wrapperScroll {
    gap: 0px;
    padding: 0px 44px;
  }

  .content {
    height: 100%;
  }

  .optionPlaceholder {
    min-width: 1px;
    max-width: 1px;
    min-height: 1px;
    max-height: 1px;
  }
}

@media only screen and (max-width: 664px) {
  .section {
    position: fixed;
    height: 100dvh;
    width: 100%;
    display: flex;
    flex-direction: column;
  }

  .app {
    overflow-y: hidden !important;
  }

  .wrapperScroll {
    gap: 0px;
    padding: 0px 0px;
  }

  .content {
    overflow-y: hidden !important;
    height: calc(100dvh - 0px);
  }
}
