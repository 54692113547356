.wrapper {
  display: grid;
  grid-template-columns: 1fr 2fr;
  gap: 8px;
  color: var(--text2);
  padding: 0px;
  background-color: transparent;
  outline: 0px solid transparent;
  border: 0px;
  text-align: left;
}

.wrapper:hover {
  cursor: pointer;
  color: var(--text1);
}

.wrapper:focus {
  cursor: pointer;
  color: var(--text1);
}

.wrapper:hover .word {
  cursor: pointer;
  border-bottom: 1px dotted var(--brand2);
}

.wrapper:focus .word {
  cursor: pointer;
  border-bottom: 1px dotted var(--brand2);
}

.word {
  width: max-content;
  height: max-content;
  color: var(--brand2);
  font-size: 14px;
  line-height: 20px;
  border-bottom: 1px dotted transparent;
  font-weight: 500;
}

.translation {
  width: 100%;
  font-size: 14px;
  line-height: 20px;
  height: max-content;
  text-align: left;
}
