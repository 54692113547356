.wrapper {
  width: max-content;
  display: flex;
  flex-direction: row;
  padding: 8px 12px;
  border-radius: var(--radius);
  gap: 8px;
  align-items: center;
  font-size: 14px;
  font-weight: 300;
  line-height: 16px;
  background-color: transparent;
  border: 0px;
}

.wrapper:hover {
  background-color: var(--surface2);
  cursor: pointer;
  color: var(--text1);
}

.wrapper:focus {
  background-color: var(--surface2);
  cursor: pointer;
  color: var(--text1);
}

/* reset */
button {
  all: unset;
}

.DropdownMenuContent {
  left: 16px;
  min-width: 180px;
  background-color: var(--surface2);
  border-radius: 6px;
  border: 1px solid var(--surface3);
  padding: 0px;
  box-shadow:
    0px 10px 38px -10px rgba(22, 23, 24, 0.35),
    0px 10px 20px -15px rgba(22, 23, 24, 0.2);
  animation-duration: 400ms;
  animation-timing-function: cubic-bezier(0.16, 1, 0.3, 1);
  will-change: transform, opacity;
  z-index: 100000;
  margin-left: 16px;
  margin-right: 16px;
}

.DropdownMenuSubContent {
  left: 16px;
  min-width: 120px;
  background-color: var(--surface2);
  border-radius: 6px;
  padding: 0px;
  box-shadow:
    0px 10px 38px -10px rgba(22, 23, 24, 0.35),
    0px 10px 20px -15px rgba(22, 23, 24, 0.2);
  animation-duration: 400ms;
  animation-timing-function: cubic-bezier(0.16, 1, 0.3, 1);
  will-change: transform, opacity;
  z-index: 1000;
}

.DropdownMenuContentWrapper {
  display: grid;
  grid-column-gap: 12px;
  padding: 4px;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-auto-flow: column;
}

.DropdownMenuContent[data-side='top'],
.DropdownMenuSubContent[data-side='top'] {
  animation-name: slideDownAndFade;
}
.DropdownMenuContent[data-side='right'],
.DropdownMenuSubContent[data-side='right'] {
  animation-name: slideLeftAndFade;
}
.DropdownMenuContent[data-side='bottom'],
.DropdownMenuSubContent[data-side='bottom'] {
  animation-name: slideUpAndFade;
}
.DropdownMenuContent[data-side='left'],
.DropdownMenuSubContent[data-side='left'] {
  animation-name: slideRightAndFade;
}

.DropdownMenuItem,
.DropdownMenuCheckboxItem,
.DropdownMenuRadioItem,
.DropdownMenuSubTrigger {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 12px;
  font-size: 14px;
  line-height: 1;
  color: var(--text1);
  border-radius: 4px;
  display: flex;
  align-items: center;
  position: relative;
  padding: 8px;
  user-select: none;
  outline: none;
}
.DropdownMenuSubTrigger[data-state='open'] {
  background-color: var(--violet-4);
  color: var(--violet-11);
}
.DropdownMenuItem[data-disabled],
.DropdownMenuCheckboxItem[data-disabled],
.DropdownMenuRadioItem[data-disabled],
.DropdownMenuSubTrigger[data-disabled] {
  color: var(--mauve-8);
  pointer-events: none;
}
.DropdownMenuItem[data-highlighted],
.DropdownMenuCheckboxItem[data-highlighted],
.DropdownMenuRadioItem[data-highlighted],
.DropdownMenuSubTrigger[data-highlighted] {
  background-color: var(--surface3);
  color: var(--violet-1);
}

.DropdownMenuLabel {
  padding-left: 25px;
  font-size: 12px;
  line-height: 25px;
  color: var(--mauve-11);
}

.DropdownMenuItemIndicator {
  position: absolute;
  left: 0;
  width: 25px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.DropdownMenuArrow {
  fill: var(--surface2);
}

.RightSlot {
  display: flex;
  flex-direction: row;
  gap: 4px;
  margin-left: auto;
  padding-left: 20px;
  color: var(--text3);
}
[data-highlighted] > .RightSlot {
  color: white;
}
[data-disabled] .RightSlot {
  color: var(--text3);
}

@keyframes slideUpAndFade {
  from {
    opacity: 0;
    transform: translateY(2px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes slideRightAndFade {
  from {
    opacity: 0;
    transform: translateX(-2px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes slideDownAndFade {
  from {
    opacity: 0;
    transform: translateY(-2px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes slideLeftAndFade {
  from {
    opacity: 0;
    transform: translateX(2px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

.icon {
  width: 16px;
  height: 16px;
}

.warning {
  color: var(--warning) !important;
}

.warning:hover {
  color: #ff8f8f !important;
}

.warning:focus {
  color: #ff8f8f !important;
}
