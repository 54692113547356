@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.wrapper {
  line-height: 0;
  padding: 0;
}

.s {
  max-width: 32px;
  min-width: 32px;
}

.m {
  max-width: 40px;
  min-width: 40px;
}

.l {
  max-width: 48px;
  min-width: 48px;
}

.primary {
  color: var(--brand2);
}

.secondary {
  color: var(--brand2);
}

.icon {
  animation: spin 1s ease-in-out infinite;
}

.icon.s {
  height: 16px;
  width: 16px;
}

.icon.m {
  height: 24px;
  width: 24px;
}

.icon.l {
  height: 32px;
  width: 32px;
}
