.column {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: var(--pad-l);
  width: 100%;
}

.wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  width: 100%;
  font-size: 13px;
  color: var(--text3);
}

.text {
  width: max-content;
}
