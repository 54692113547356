.options {
  width: 100%;
  height: 100%;
  min-width: 400px;
  max-width: 700px;
  display: flex;
  flex-direction: column;
  overflow-y: hidden;
  gap: 8px;
  padding-top: 2px;
}

.top {
  display: flex;
  flex-direction: column;
  padding: 16px 0px 0px 0px;
}

.title {
  color: var(--text1);
  font-size: 16px;
  line-height: 20px;
}

.header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0px 0px 8px 0px;
  color: var(--text3);
}

.word {
  width: 100%;
  /* padding-bottom: 20px; */
  /* margin-top: -8px; */
}

.paddingBottom {
  padding-bottom: 4px;
}

.content {
  width: 100%;
  height: calc(100dvh - 154px);
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding-right: 24px;
  padding-bottom: 56px;
  align-items: center;
  justify-content: center;
  background-color: var(--surface1);
  color: var(--text3);
  border-radius: 16px;
}

.textAria {
  width: 100%;
  background-color: transparent;
  resize: none;
  appearance: none;
  outline: 0px solid transparent;
  border: 0px;
  padding: 0px;
  padding-right: 12px;
  font-size: 14px;
  line-height: 22px;
}

.textAria:focus {
  outline: 0;
}

.firstText {
  color: var(--text-yellow));
}

.secondText {
  color: var(--brand2);
}

.status {
  color: green;
  font-size: 14px;
  font-weight: 600;
}

.status:hover {
  cursor: pointer;
}

.apply {
  color: var(--brand2);
  font-size: 14px;
  font-weight: 600;
}

.apply:hover {
  cursor: pointer;
}

.applyButton {
  display: none;
}

@media only screen and (max-width: 1360px) {
}

@media only screen and (max-width: 1100px) {
  .optionPlaceholder {
    min-width: 1px;
    max-width: 1px;
  }

  .options {
    display: none;
    position: absolute;
    z-index: 1000;
    right: 0;
    top: 0;
    bottom: 0;
    left: auto;
    background-color: var(--surface2);
    width: 100%;
    max-width: 400px;
    flex-direction: column;
    box-shadow: var(--surface-shadow);
  }

  .header {
    padding: 16px 16px 16px 24px;
  }

  .content {
    height: 100dvh;
    padding-right: 24px;
    padding-bottom: 56px;
    padding-left: 24px;
  }
}

@media only screen and (max-width: 664px) {
  .options {
    position: absolute;
    z-index: 10;
    right: 0;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    min-width: 100%;
    flex-direction: column;
  }

  .applyButton {
    display: flex;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    top: auto;
    width: 100%;
    padding: 16px 24px;
  }
}
