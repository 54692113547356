.options {
  width: 100%;
  height: 100%;
  min-width: 400px;
  max-width: 700px;
  display: flex;
  flex-direction: column;
  overflow-y: hidden;
  padding-top: 16px;
}

.hide {
  display: none;
}
