/* @import '@radix-ui/colors/black-alpha.css';
@import '@radix-ui/colors/green.css';
@import '@radix-ui/colors/mauve.css';
@import '@radix-ui/colors/violet.css'; */

/* reset */
button,
fieldset,
input {
  all: unset;
}

.closeButton {
  display: none;
}

.DialogOverlay {
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  inset: 0;
  animation: overlayShow 150ms cubic-bezier(0.16, 1, 0.3, 1);
  z-index: 10000;
  backdrop-filter: blur(1px);
}

.DialogContent {
  border-radius: 8px;
  border: 1px solid var(--surface3);
  display: flex;
  flex-direction: column;
  background-color: var(--surface1);
  /* border-radius: var(--radius); */
  box-shadow:
    hsl(206 22% 7% / 35%) 0px 10px 38px -10px,
    hsl(206 22% 7% / 20%) 0px 10px 20px -15px;
  position: fixed;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 90vw;
  max-width: 870px;
  height: 620px;
  max-height: 85vh;
  animation: contentShow 150ms cubic-bezier(0.16, 1, 0.3, 1);
  z-index: 10001;
}
.DialogContent:focus {
  outline: none;
}

.DialogTitle {
  font-family: Georgia, 'Times New Roman', Times, serif;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 8px;
  align-items: center;
  padding: 16px 20px 24px 20px;
  margin: 0;
  color: var(--text1);
  font-size: 28px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.DialogDescription {
  display: flex;
  flex-direction: row;
  gap: 12px;
  color: var(--text2);
  font-size: 16px;
  line-height: 1.5;
  padding-bottom: 12px;
}

.proPlan {
  background-color: var(--text1);
  color: var(--surface1);
  padding: 2px 8px;
  border-radius: var(--radius);
  align-self: flex-start;
}

.sidebar {
  min-width: 220px;
  display: flex;
  flex-direction: column;
}

.wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.windowTitle {
  display: flex;
  padding: 12px 16px 24px 16px;
  align-items: flex-start;
  align-self: stretch;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
}

.content {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  overflow: hidden;
  width: 100%;
  height: 100%;
  padding: 0px 0px 0px 20px;
  gap: 32px;
}

.contentWrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
  max-width: 400px;
  width: 100%;
}

.featureRow {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 8px;
  padding-bottom: 8px;
}

.icon {
  width: 16px;
  height: 16px;
}

.iconBulet {
  width: 16px;
  height: 16px;
  padding-top: 4px;
}

.feature {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 0px;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  color: var(--text2);
}

.featureTitle {
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 1.5;
  color: var(--text1);
}

.buttonWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 12px 0px;
  width: 100%;
}

.leftBlock {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.rightBlock {
  display: flex;
  flex-direction: column;
  width: 120px;
}

.bage {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  background-color: var(--brand);
  color: white;
  font-size: 10px;
  font-style: normal;
  font-weight: 600;
  line-height: 1;
  padding: 4px 6px;
  text-transform: uppercase;
  max-width: max-content;
}

.priceWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 4px;
}

.price {
  font-size: 14px;
  font-weight: 500;
  color: var(--text1);
}

.save {
  font-size: 12px;
  line-height: normal;
  font-weight: 400;
  color: var(--text2);
  padding: 2px 4px;
  background-color: var(--surface3);
  border-radius: 4px;
  max-width: max-content;
}

.month {
  font-size: 14px;
  font-weight: 400;
  color: var(--text2);
}

.titleWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
}

.planTitle {
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
}

.planDescription {
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  color: var(--text2);
}

.buttonTop {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 72px;
  border-radius: var(--radius) var(--radius) 0 0;
  border: 1px solid var(--surface3);
  padding: 8px 12px;
  box-sizing: border-box;
}

.buttonTop:hover {
  background-color: var(--surface2);
  cursor: pointer;
}

.buttonTop:focus {
  background-color: var(--surface2);
  border: 2px solid var(--brand);
  padding: 7px 11px;
}

.buttonMiddle {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 8px 12px;
  border-left: 1px solid var(--surface3);
  border-right: 1px solid var(--surface3);
  height: 72px;
  box-sizing: border-box;
}

.buttonMiddle:hover {
  background-color: var(--surface2);
  cursor: pointer;
}

.buttonMiddle:focus {
  background-color: var(--surface2);
  border: 2px solid var(--brand);
  padding: 7px 11px;
}

.buttonBottom {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  border-radius: 0 0 var(--radius) var(--radius);
  border: 1px solid var(--surface3);
  padding: 8px 12px;
  height: 72px;
  box-sizing: border-box;
}

.buttonBottom:hover {
  background-color: var(--surface2);
  cursor: pointer;
}

.buttonBottom:focus {
  background-color: var(--surface2);
  border: 2px solid var(--brand);
  padding: 7px 11px;
}

.item {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 12px;
  font-size: 14px;
  line-height: 1;
  color: var(--text1);
  border-radius: 4px;
  display: flex;
  align-items: center;
  position: relative;
  padding: 8px;
  user-select: none;
  outline: none;
}

.item:hover {
  background-color: var(--surface3);
  cursor: pointer;
}

.item:focus {
  background-color: var(--surface3);
}

.upgradeImage {
  width: 100%;
  height: 100%;
  background-image: url('../assets/upgrade-dark.svg');
  background-repeat: no-repeat;
  opacity: 0.5;
}

@keyframes overlayShow {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes contentShow {
  from {
    opacity: 0;
    transform: translate(-50%, -48%) scale(0.96);
  }
  to {
    opacity: 1;
    transform: translate(-50%, -50%) scale(1);
  }
}

@media only screen and (max-width: 1550px) {
}

@media only screen and (max-width: 1360px) {
}

@media only screen and (max-width: 840px) {
  .upgradeImage {
    display: none;
  }
  .closeButton {
    display: block;
    color: var(--text3);
  }
  .content {
    display: flex;
    flex-direction: column;
    overflow: hidden;
    width: 100%;
    height: 100%;
    padding: 0px 20px 0px 20px;
    gap: 32px;
  }

  .contentWrapper {
    display: flex;
    flex-direction: column;
    height: 100%;
    min-width: 100%;
  }
}

@media only screen and (max-width: 664px) {
  .DialogTitle {
    font-family: Georgia, 'Times New Roman', Times, serif;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 8px;
    align-items: center;
    padding: 16px 20px 32px 20px;
    margin: 0;
    color: var(--text1);
    font-size: 28px;
    font-style: normal;
    /* font-weight: 600; */
    line-height: normal;
  }

  .DialogOverlay {
    background-color: rgba(0, 0, 0, 0.5);
    position: fixed;
    inset: 0;
    animation: none !important;
    z-index: 10000;
    backdrop-filter: blur(1px);
  }

  .DialogContent {
    border-radius: 8px;
    border: 1px solid var(--surface3);
    display: flex;
    flex-direction: column;
    background-color: var(--surface1);
    /* box-shadow:
        hsl(206 22% 7% / 35%) 0px 10px 38px -10px,
        hsl(206 22% 7% / 20%) 0px 10px 20px -15px; */
    position: fixed;
    top: 51%;
    transform: translate(-50%, -50%);
    width: 100vw;
    min-height: 660px;
    animation: none !important;
    z-index: 10001;
  }
  .content {
    display: flex;
    flex-direction: row;
    justify-content: center;
    overflow: hidden;
    width: 100%;
    height: 100%;
    padding: 0px 20px 0px 20px;
    gap: 32px;
  }
}
