.icon {
  width: 16px;
  height: 16px;
}

.menuItem {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 6px;
  cursor: pointer;
  color: var(--text2);
  height: 20px;
  width: 20px;
}

.menuItem.active {
  color: var(--brand);
  background-color: var(--surface1);
  border-radius: var(--radius);
  box-shadow: inset 0 0 0 2px var(--surface2);
}
.menuItem:hover {
  color: var(--brand);
  background-color: var(--surface1);
  border-radius: var(--radius);
}

.menuItem:focused {
  color: var(--brand);
  background-color: var(--surface1);
  border-radius: var(--radius);
}
