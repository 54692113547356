.options {
  width: 100%;
  height: 100%;
  min-width: 400px;
  max-width: 700px;
  display: flex;
  flex-direction: column;
  overflow-y: hidden;
  padding-top: 16px;
  z-index: 10000 !important;
}

.wrapper {
  display: flex;
  flex-direction: column;
  padding-bottom: 28px;
}

.title {
  color: var(--text2);
  font-size: 14px;
  line-height: 16px;
}

.titleTrans {
  color: var(--text2);
  font-size: 14px;
  line-height: 16px;
  padding-bottom: 8px;
  display: flex;
  flex-direction: row;
  align-items: center;
  min-height: 24px;
}

.header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 4px 16px 8px 0px;
  color: var(--text3);
}

.textAria {
  width: 100%;
  background-color: transparent;
  resize: none;
  appearance: none;
  outline: 0px solid transparent;
  border: 0px;
  padding: 0px;
  padding-right: 12px;
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
}

.textAria:focus {
  outline: 0;
}

.firstText {
  color: var(--text-for-translate);
}

.word {
  width: 100%;
  padding-bottom: 32px;
  font-size: 14px;
  line-height: 16px;
  color: var(--text-yellow);
}

.content {
  width: 100%;
  height: calc(100dvh - 180px);
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding-right: 24px;
  padding-bottom: 56px;
}

.status {
  color: var(--success);
  font-size: 12px;
  font-weight: 600;
}

.status:hover {
  cursor: pointer;
  /* border-bottom: 1px dotted green; */
}

.paddingBottom {
  padding-bottom: 0px !important;
}

.buttonWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
}

.hidden {
  display: none;
}

.secondText {
  color: var(--brand2);
}

.secondText:hover {
  color: var(--brand2);
  /* background-color: var(--surface2);
  cursor: pointer; */
}

.applyButton {
  display: none;
}

@media only screen and (max-width: 1360px) {
  .header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 4px 0px 8px 0px;
    color: var(--text3);
  }

  .optionPlaceholder {
    min-width: 1px;
    max-width: 1px;
  }

  .options {
    padding-top: 8px;
    display: flex;
    position: fixed;
    z-index: 100000;
    right: 0;
    top: 0;
    bottom: 0;
    left: auto;
    background-color: var(--surface1);
    width: 100%;
    max-width: 500px;
    flex-direction: column;
    box-shadow: var(--surface-shadow);
  }

  .hidden {
    display: none;
  }

  .wrapper {
    padding: 0px 32px 20px 32px;
  }

  .content {
    height: 100dvh;
    padding: 0px 32px 56px 32px;
  }
}

@media only screen and (max-width: 1100px) {
  .optionPlaceholder {
    min-width: 1px;
    max-width: 1px;
  }

  .options {
    display: flex;
    position: fixed;
    right: 0;
    top: 0;
    bottom: 0;
    left: auto;
    background-color: var(--surface1);
    width: 100%;
    max-width: 500px;
    flex-direction: column;
    box-shadow: var(--surface-shadow);
    z-index: 10000 !important;
  }

  .hidden {
    display: none;
  }

  .wrapper {
    padding: 0px 32px 20px 32px;
  }

  .content {
    height: 100dvh;
    padding: 0px 32px 56px 32px;
  }
}

@media only screen and (max-width: 664px) {
  .options {
    position: fixed;
    right: 0;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    min-width: 100%;
    flex-direction: column;
    z-index: 10000 !important;
  }
  .hidden {
    display: none;
  }

  .applyButton {
    display: flex;
    width: 100%;
    padding: 16px 16px;
  }
}

@media only screen and (max-width: 540px) {
  .content {
    padding: 0px 16px 56px 16px;
  }

  .wrapper {
    padding: 0px 16px 20px 16px;
  }
}

.TooltipContent {
  border-radius: var(--radius);
  padding: 8px 12px;
  font-size: 14px;
  line-height: 1;
  color: var(--violet-11);
  background-color: rgba(0, 0, 0, 0.9);
  box-shadow:
    hsl(206 22% 7% / 35%) 0px 10px 38px -10px,
    hsl(206 22% 7% / 20%) 0px 10px 20px -15px;
  user-select: none;
  animation-duration: 400ms;
  animation-timing-function: cubic-bezier(0.16, 1, 0.3, 1);
  will-change: transform, opacity;
  z-index: 100;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
}
.TooltipContent[data-state='delayed-open'][data-side='top'] {
  animation-name: slideDownAndFade;
}
.TooltipContent[data-state='delayed-open'][data-side='right'] {
  animation-name: slideLeftAndFade;
}
.TooltipContent[data-state='delayed-open'][data-side='bottom'] {
  animation-name: slideUpAndFade;
}
.TooltipContent[data-state='delayed-open'][data-side='left'] {
  animation-name: slideRightAndFade;
}

.TooltipTriger {
  width: 100%;
}

.TooltipArrow {
  fill: rgba(0, 0, 0, 0.7);
}

.IconButton {
  font-family: inherit;
  border-radius: 100%;
  height: 35px;
  width: 35px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  color: var(--violet-11);
  background-color: white;
  box-shadow: 0 2px 10px var(--black-a7);
}
.IconButton:hover {
  background-color: var(--violet-3);
}
.IconButton:focus {
  box-shadow: 0 0 0 2px black;
}

@keyframes slideUpAndFade {
  from {
    opacity: 0;
    transform: translateY(2px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes slideRightAndFade {
  from {
    opacity: 0;
    transform: translateX(-2px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes slideDownAndFade {
  from {
    opacity: 0;
    transform: translateY(-2px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes slideLeftAndFade {
  from {
    opacity: 0;
    transform: translateX(2px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

.RightSlot {
  margin-left: auto;
  padding-left: 8px;
  color: var(--text2);
  font-size: 14px;
}
[data-highlighted] > .RightSlot {
  color: white;
}
[data-disabled] .RightSlot {
  color: var(--text2);
}
