/* reset */
button,
fieldset,
input {
  all: unset;
}

.DialogOverlay {
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  inset: 0;
  animation: overlayShow 150ms cubic-bezier(0.16, 1, 0.3, 1);
  z-index: 10000;
  backdrop-filter: blur(1px);
}

.DialogContent {
  border-radius: 8px;
  border: 1px solid var(--surface3);
  display: flex;
  flex-direction: column;
  background-color: var(--surface1);
  box-shadow:
    hsl(206 22% 7% / 35%) 0px 10px 38px -10px,
    hsl(206 22% 7% / 20%) 0px 10px 20px -15px;
  position: fixed;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 90vw;
  max-width: 870px;
  height: 620px;
  max-height: 85vh;
  animation: contentShow 150ms cubic-bezier(0.16, 1, 0.3, 1);
  z-index: 10001;
}
.DialogContent:focus {
  outline: none;
}

.DialogTitle {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 8px 8px 8px 20px;
  margin: 0;
  color: var(--text1);
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.DialogDescription {
  display: flex;
  flex-direction: row;
  gap: 12px;
  padding: 8px 12px;
  color: white;
  font-size: 15px;
  line-height: 1.5;
  background-color: var(--brand);
}

.sidebar {
  min-width: 220px;
  display: flex;
  flex-direction: column;
}

.wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.windowTitle {
  display: flex;
  padding: 12px 16px 24px 16px;
  align-items: flex-start;
  align-self: stretch;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
}

.content {
  display: flex;
  flex-direction: row;
  flex: 1;
  overflow: hidden;
  height: 100%;
}

.contentWrapper {
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 100%;
  width: 100%;
}

.note {
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 100%;
  width: 100%;
}

.buttonWrapper {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  padding: 12px 16px;
  gap: 8px;
}

.allNotes {
  max-width: 280px;
  display: flex;
  flex-direction: column;
  flex: 1;
  gap: 8px;
  height: 100%;
  width: 100%;
  overflow: scroll;
  padding: 16px 20px;
}

.allNotesActions {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 12px 16px;
  gap: 8px;
}

.allNotesActionsText {
  font-size: 13px;
  line-height: 1.3;
  color: var(--text3);
  max-width: 240px;
}

.note {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: scroll;
  padding: 28px 16px;
}

.noteTitle {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 12px 16px;
  gap: 8px;
  font-size: 18px;
  line-height: 1.3;
  color: var(--text1);
  font-weight: 600;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.noteBody {
  padding: 12px 16px;
  gap: 8px;
  font-size: 16px;
  line-height: 1.7;
  color: var(--text2);
}

.item {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 12px;
  font-size: 14px;
  line-height: 1;
  color: var(--text1);
  border-radius: 4px;
  display: flex;
  align-items: center;
  position: relative;
  padding: 8px;
  user-select: none;
  outline: none;
}

.item:hover {
  background-color: var(--surface3);
  cursor: pointer;
}

.item:focus {
  background-color: var(--surface3);
}

.icon {
  width: 16px;
  height: 16px;
}

.IconButton {
  font-family: inherit;
  border-radius: var(--radius);
  height: 32px;
  width: 32px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  color: var(--text2);
  border: 1px solid transparent;
  box-sizing: border-box;
}

.IconButton:hover {
  background-color: var(--surface2);
  color: var(--text1);
  cursor: pointer;
}

.IconButton:focus {
  background-color: var(--surface2);
  /* border: 1px solid var(--brand); */
}

@keyframes overlayShow {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes contentShow {
  from {
    opacity: 0;
    transform: translate(-50%, -48%) scale(0.96);
  }
  to {
    opacity: 1;
    transform: translate(-50%, -50%) scale(1);
  }
}

@media only screen and (max-width: 664px) {
  .DialogOverlay {
    background-color: rgba(0, 0, 0, 0.5);
    position: fixed;
    inset: 0;
    animation: none !important;
    z-index: 10000;
    backdrop-filter: blur(1px);
  }

  .DialogContent {
    border-radius: 8px;
    border: 1px solid var(--surface3);
    display: flex;
    flex-direction: column;
    background-color: var(--surface1);
    box-shadow:
      hsl(206 22% 7% / 35%) 0px 10px 38px -10px,
      hsl(206 22% 7% / 20%) 0px 10px 20px -15px;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100vw;
    max-width: 870px;
    height: 100dvh;
    max-height: 85vh;
    animation: none !important;
    z-index: 10001;
  }
}
