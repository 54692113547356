button {
  all: unset;
}

.deletedNote {
  display: flex;
  flex-direction: column;
  height: max-content;
  padding: 8px 12px;
  border-radius: var(--radius);
  background-color: var(--surface2);
  border: 1px solid var(--surface2);
}

.deletedNote:hover {
  border: 1px solid var(--surface3);
  cursor: pointer;
}

.deletedNote:focus {
  border: 1px solid var(--surface5);
  cursor: pointer;
}

.deletedNoteTitle {
  font-size: 14px;
  font-weight: 500;
  line-height: 1.3;
  color: var(--text1);
}

.deletedNoteBody {
  font-size: 12px;
  line-height: 1.3;
  color: var(--text2);
}

.deletedNoteActive {
  display: flex;
  flex-direction: column;
  height: max-content;
  padding: 8px 12px;
  border-radius: var(--radius);
  background-color: var(--surface2);
  border: 1px solid var(--brand2);
}
