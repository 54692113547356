.wrapper {
  display: flex;
  flex-direction: column;
  padding: 4px 8px 6px 8px;
  border-radius: var(--radius);
  background-color: transparent;
  border: 0;
  appearance: none;
  outline: none;
  width: -webkit-fill-available;
  width: -moz-available;
}

.wrapper:hover {
  background-color: var(--surface2);
  cursor: pointer;
}

.wrapper:focus {
  background-color: var(--surface2);
  cursor: pointer;
}

.currentPlan {
  display: flax;
  align-items: baseline;
}

.planName {
  font-size: 14px;
  font-weight: 500;
  padding-right: 4px;
}

.words {
  font-size: 14px;
  color: var(--text2);
}

.getMore {
  color: var(--brand2);
  font-size: 13px;
  line-height: 16px;
  font-weight: 500;
}
