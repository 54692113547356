.header {
  padding: 8px 16px 8px 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: var(--surface1);
}

.user {
  max-width: 260px;
}

.logo {
  min-height: 24px;
  min-width: 24px;
  max-width: 24px;
  max-height: 24px;
}

.headetText {
  color: var(--text2);
  font-size: 14px;
}

.headetText2 {
  color: var(--text1);
  font-size: 16px;
  font-weight: 500;
  margin-right: 48px;
}

.section {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: calc(100dvh - 48px);
  overflow-y: auto;
  padding-bottom: 32px;
}

.container {
  height: 100%;
}

.form {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  margin-top: 32px;
  background-color: var(--surface1);
  padding: 20px 32px 32px 32px;
  border-radius: 8px;
}

.notif {
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding: 20px 32px 32px 32px;
  border-radius: 8px;
  background-color: var(--surface1);
  margin-top: 32px;
  max-width: 700px;
}

.about {
  margin-top: 0px !important;
}

.buttonA {
  width: 400px;
}

.access {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  gap: 12px;
}

.ul2 {
  padding-left: 24px;
}

.link {
  color: white;
  border-radius: 6px;
  border: 1px solid var(--brand2);
  background-color: var(--brand);
  text-decoration: none;
  padding: 8px 24px;
  width: fit-content;
  margin-top: 12px;
  text-transform: uppercase;
  font-size: 14px;
  line-height: 26px;
  font-weight: 700;
  /* margin-bottom: 32px; */
}

.link:hover {
  background-color: var(--brand2);
  color: white;
  border: 1px solid var(--brand);
  cursor: pointer;
}

.link:focus {
  background-color: var(--brand2);
  color: white;
  border: 1px solid var(--brand);
  cursor: pointer;
}

.divider {
  display: none;
  margin-top: 32px;
  margin-bottom: 24px;
}

.alert {
  display: block;
  font-size: 13px;
  font-weight: 600;
  color: var(--warning);
}

@media only screen and (max-width: 664px) {
  .header {
    padding: 8px 16px 8px 8px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: var(--surface1);
  }

  .user {
    max-width: 260px;
  }

  .logo {
    min-height: 24px;
    min-width: 24px;
    max-width: 24px;
    max-height: 24px;
  }

  .notif {
    display: flex;
    flex-direction: column;
    gap: 6px;
    padding: 8px 16px;
    border-radius: 8px;
    background-color: transparent;
    margin: 0px;
    max-width: 700px;
  }

  .form {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 6px;
    margin-top: 8px;
    background-color: transparent;
    padding: 8px 16px;
    border-radius: 8px;
  }

  .ul2 {
    padding-left: 16px;
  }

  .access {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 12px;
    width: 100%;
  }

  .buttonA {
    width: fit-content;
  }

  .divider {
    display: block;
    margin-top: 32px;
    margin-bottom: 24px;
  }

  .headetText2 {
    display: none;
  }
}
