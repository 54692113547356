.toolbarWrapper {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.toolbarSlim {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 8px 0px;
  margin-left: -18px;
  color: var(--text2);
  max-width: 814px;
}

.toolbarWide {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 8px 0px;
  color: var(--text2);
}

.menuLeft {
  position: absolute;
  left: 12px;
  top: 8px;
  display: flex;
  justify-content: right;
  gap: 4px;
  align-items: center;
  height: 32px;
}

.hideMenuleft {
  display: none;
}

.pageMenu {
  max-width: 824px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 32px;
  padding: 0px 24px;
  gap: 48px;
}

.pageMenuWide {
  height: 100%;
  width: 100%;
  display: grid;
  grid-template-columns: 2fr 1fr;
  justify-content: center;
  gap: 32px;
  margin-right: 56px;
}

.pageMenuConteiner {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 0px;
}

.pageMenuConteinerWide {
  display: flex;
  justify-content: center;
  /* padding-left: 32px; */
}

.pageMenuNav {
  display: flex;
  flex-direction: row;
  justify-content: left;
  align-items: center;
  gap: 4px;
  margin-left: -8px;
}

.pageMenuNavWide {
  width: 100%;
  min-width: 600px;
  max-width: 756px;
  display: flex;
  flex-direction: row;
  justify-content: left;
  align-items: center;
  gap: 4px;
  padding-left: 24px;
  margin-right: 24px;
}

.pageMenuEditor {
  display: flex;
  flex-direction: row;
  justify-content: left;
  align-items: center;
  gap: 4px;
  z-index: 300;
}

.pageMenuEditorWide {
  min-width: 727px;
  display: flex;
  flex-direction: row;
  justify-content: left;
  align-items: center;
  gap: 4px;
}

.pageMenuLang {
  width: max-content;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 16px;
}

.pageMenuLangWide {
  width: 100%;
  height: 100%;
  min-width: 400px;
  max-width: 700px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 12px;
  margin-right: 56px;
}

.menuRight {
  color: var(--text2);
  position: absolute;
  right: 12px;
  top: 8px;
  display: flex;
  justify-content: right;
  gap: 4px;
  align-items: center;
  height: 32px;
}

.dividerPad {
  height: 16px;
}

.dividerLang {
  display: none;
  height: 16px;
}

.icon {
  height: 16px;
  width: 16px;
}

.extraButtons {
  display: flex;
  gap: 2px;
}

.hideButton {
  display: none;
}

.showPlaceholder {
  width: 420px;
  display: flex;
  justify-content: left;
  align-self: start;
}

.hidePlaceholder {
  width: max-content;
  display: flex;
  justify-content: left;
  align-self: start;
}

@media only screen and (max-width: 1368px) {
  .showButton {
    display: block;
    position: static;
  }

  .pageMenuWide {
    min-width: 600px;
    max-width: 756px;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    height: 32px;
    padding: 0px;
    gap: 76px;
  }

  .pageMenuNavWide {
    width: 100%;
    min-width: 20px;
    max-width: max-content;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 4px;
    padding-left: 0px;
    margin-right: 0px;
    margin-left: -8px;
  }

  .pageMenuLangWide {
    width: 100%;
    height: 100%;
    min-width: max-content;
    max-width: max-content;
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }
}

@media only screen and (max-width: 1100px) {
  .toolbarSlim {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    gap: 84px;
    padding: 8px 0px;
    margin-left: -18px;
    color: var(--text2);
    max-width: 760px;
  }

  .toolbarWide {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 84px;
    padding: 8px 0px;
    /* margin-left: -18px; */
    color: var(--text2);
  }

  .pageMenuNavWide {
    display: flex;
    flex-direction: row;
    justify-content: left;
    align-items: center;
    gap: 4px;
    margin-left: -8px;
  }

  .pageMenu {
    justify-content: left;
    gap: 4px;
  }

  .dividerLang {
    display: none;
    height: 16px;
  }

  .pageMenuConteiner {
    display: flex;
    flex-direction: row;
    justify-content: left;
    align-items: center;
  }
}

@media only screen and (max-width: 872px) {
  .toolbarSlim {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding: 8px 0px;
    margin-right: 32px;
    color: var(--text2);
    max-width: 760px;
  }

  .pageMenu {
    padding: 0px 0px 0px 8px;
  }

  .toolbar {
    padding: 8px 8px;
  }

  .menuLeft {
    position: static;
    display: flex;
    gap: 4px;
    height: 32px;
  }

  .dividerMenu {
    display: block;
    height: 16px;
  }

  .pageMenuWide {
    max-width: max-content;
    /* width: 100%;  */
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 32px;
    padding: 0px;
    gap: 4px;
    margin-right: 36px;
  }

  .pageMenuEditor {
    width: 100%;
    display: flex;
    justify-content: left;
    gap: 8px;
    position: fixed;
    top: auto;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: var(--background);
    padding: 8px 8px;
    z-index: 300;
  }
}

@media only screen and (max-width: 664px) {
  .toolbarSlim {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: left;
    padding: 8px 0px;
    margin-left: -48px;
    color: var(--text2);
    max-width: 760px;
  }

  .menuLeft {
    display: flex;
    gap: 4px;
    height: 32px;
  }

  .menuMiddle {
    gap: 4px;
    justify-content: left;
  }

  .menuRight {
    gap: 4px;
  }

  .pageMenuWide {
    min-width: 100%;
    max-width: max-content;
    width: max-content;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    height: 32px;
    padding: 0px;
    gap: 4px;
    margin-left: 104px;
  }

  .dividerLang {
    display: block;
    height: 16px;
  }

  .extraButtons {
    display: none;
  }
}

@media only screen and (max-width: 400px) {
  .extraButtons {
    display: none;
  }
}

.TooltipContent {
  border-radius: var(--radius);
  padding: 8px 12px;
  font-size: 14px;
  line-height: 1;
  color: whitesmoke;
  background-color: rgba(0, 0, 0, 0.9);
  box-shadow:
    hsl(206 22% 7% / 35%) 0px 10px 38px -10px,
    hsl(206 22% 7% / 20%) 0px 10px 20px -15px;
  user-select: none;
  animation-duration: 400ms;
  animation-timing-function: cubic-bezier(0.16, 1, 0.3, 1);
  will-change: transform, opacity;
}
.TooltipContent[data-state='delayed-open'][data-side='top'] {
  animation-name: slideDownAndFade;
}
.TooltipContent[data-state='delayed-open'][data-side='right'] {
  animation-name: slideLeftAndFade;
}
.TooltipContent[data-state='delayed-open'][data-side='bottom'] {
  animation-name: slideUpAndFade;
}
.TooltipContent[data-state='delayed-open'][data-side='left'] {
  animation-name: slideRightAndFade;
}

.TooltipArrow {
  fill: rgba(0, 0, 0, 0.7);
}

.IconButton {
  font-family: inherit;
  border-radius: 100%;
  height: 35px;
  width: 35px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  color: var(--violet-11);
  background-color: white;
  box-shadow: 0 2px 10px var(--black-a7);
}
.IconButton:hover {
  background-color: var(--violet-3);
}
.IconButton:focus {
  box-shadow: 0 0 0 2px black;
}

@keyframes slideUpAndFade {
  from {
    opacity: 0;
    transform: translateY(2px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes slideRightAndFade {
  from {
    opacity: 0;
    transform: translateX(-2px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes slideDownAndFade {
  from {
    opacity: 0;
    transform: translateY(-2px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes slideLeftAndFade {
  from {
    opacity: 0;
    transform: translateX(2px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}
