.Placeholder {
  min-width: 400px;
  max-width: 780px;
  z-index: 1000;
}

@media only screen and (max-width: 1360px) {
  .Placeholder {
    /* display: none; */
    min-width: 1px;
    max-width: 1px;
    z-index: 1000;
  }
}

@media only screen and (max-width: 1100px) {
}

@media only screen and (max-width: 790px) {
}
