.wrapper {
  font: inherit;
  margin-top: 20px;
  width: 100%;
}

.textarea {
  background: transparent;
  border: 0;
  width: 100%;
  height: min-content;
  word-break: break-word;
  white-space: pre-wrap;
  background-color: transparent;
  resize: none;
  appearance: none;
  outline: 0px solid transparent;
  padding: 0px;
}

.textarea:focus {
  outline: 0;
}
.textarea::placeholder {
  color: var(--text3);
}

@media only screen and (max-width: 664px) {
  .wrapper {
    font: inherit;
    /* height: 40px; */
    margin-top: 8px;
  }
}
