.horizontal {
  height: 1px;
  min-height: 1px;
  max-height: 1px;
  flex: 1 0 0;
  background-color: var(--surface3);
  width: 100%;
}

.vertical {
  height: 100%;
  width: 1px;
  min-width: 1px;
  max-width: 1px;
  background-color: var(--surface3);
}
