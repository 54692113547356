.wrapper {
  display: flex;
  flex-direction: row;
  gap: 8px;
  appearance: none;
  -webkit-appearance: none;
  background: transparent;
  border: 0;
  border-radius: var(--radius);
  box-sizing: border-box;
  cursor: pointer;
  padding: 8px;
  text-align: left;
  transition: var(--transition);
  transition-property: background-color;
  width: 100%;
  height: 32px;
  align-items: center;
}

.wrapper:hover {
  background-color: var(--surface2);
}

.wrapper:focus {
  background-color: var(--surface2);
}

.wrapperIsActive {
  background-color: var(--surface3);
}

.title {
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
  white-space: nowrap;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
}

.icon {
  height: 18px;
  width: 18px;
}
