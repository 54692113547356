.foreignLanguage {
  font-family: var(--font-mono);
  line-height: 24px;
  color: var(--text-yellow);
}

.foreignLanguage:focus {
  outline: 0;
}

.foreignLanguage:hover {
  color: var(--text-yellow);
  background-color: var(--surface2);
  cursor: pointer;
}

.foreignLanguage:focus {
  color: var(--text-yellow);
  background-color: var(--surface2);
  cursor: pointer;
}

.nativeLanguage {
  color: var(--text1);
}

.warningLanguage {
  color: var(--warning);
}

.editable {
  padding-bottom: 50vh;
  font-size: 18px !important;
  line-height: 32px !important;
  width: 100%;
}

.toolbar {
  display: flex;
  align-items: flex-start;
  width: 680px;
  /* margin-left: -14px; Magic number offsets leading bold button */
}

.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0px 32px;
  position: fixed;
  top: auto;
  bottom: 0%;
  left: 272px;
  right: 0%;
  background-color: var(--surface2);
  width: 100%;
  z-index: 300;
  background-color: black;
}

/* @media (pointer: coarse) {
    .toolbar {
      display: none;
    }
  } */

@media only screen and (max-width: 664px) {
  .wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0px 32px;
    position: fixed;
    top: auto;
    bottom: 0%;
    left: 0px;
    right: 0%;
    background-color: var(--surface2);
    width: 100%;
  }
}
