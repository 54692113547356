* {
  /* brand foundation */
  /* --brand-hue: 79;
    --brand-saturation: 63%;
    --brand-lightness: 64%; */

  /* brand foundation */
  --brand-hue: 210;
  --brand-saturation: 70%;
  --brand-lightness: 40%;

  /* light */
  --brand-light: hsl(
    var(--brand-hue) var(--brand-saturation) var(--brand-lightness)
  );
  --text1-light: hsl(var(--brand-hue) var(--brand-saturation) 10%);
  --text2-light: hsl(var(--brand-hue) 30% 40%);
  --text3-light: hsl(var(--brand-hue) 30% 70%);
  --text-yellow-light: hsl(29, 68%, 54%);

  --text-for-translate-light: hsl(29, 68%, 54%);
  --text-grammar-light: hsl(120, 100%, 25%);
  --text-is-translate-light: hsl(
    var(--brand-hue) var(--brand-saturation) calc(var(--brand-lightness) + 10%)
  );

  --surface1-light: hsl(var(--brand-hue) 25% 96%);
  --surface2-light: hsl(var(--brand-hue) 20% 92%);
  --surface3-light: hsl(var(--brand-hue) 20% 88%);
  --surface4-light: hsl(var(--brand-hue) 20% 84%);
  --surface5-light: hsl(var(--brand-hue) 70% 80%);

  --surface-shadow-light: var(--brand-hue) 10% 20%;
  --shadow-strength-light: 0.02;
  --background-light: hsl(0% 0% 100%);

  --warning-light: hsl(0, 100%, 50%); /* Красный */
  --warning-light-hover: hsl(0, 100%, 40%);
  --success-light: hsl(120, 100%, 25%); /* Зеленый */
  --success-light-hover: hsl(120, 100%, 20%);
  --attention-light: hsl(60, 100%, 50%); /* Желтый */
  --attention-light-hover: hsl(60, 100%, 40%);

  /* dark */
  --brand-dark: hsl(
    var(--brand-hue) calc(var(--brand-saturation) / 1)
      calc(var(--brand-lightness) * 1.3)
  );
  --text1-dark: hsl(var(--brand-hue) 15% 85%);
  --text2-dark: hsl(var(--brand-hue) 5% 65%);
  --text3-dark: hsl(var(--brand-hue) 5% 45%);
  --text-yellow-dark: hsl(50, 96%, 55%);

  --text-for-translate-dark: hsl(50, 96%, 55%); /* Был --text-yellow-dark */
  --text-grammar-dark: hsl(120, 100%, 35%); /* Зеленый */
  --text-is-translate-dark: hsl(
    var(--brand-hue) calc(var(--brand-saturation) / 1)
      calc(var(--brand-lightness) * 1.2)
  );

  --surface1-dark: hsl(var(--brand-hue) 10% 12%);
  --surface2-dark: hsl(var(--brand-hue) 10% 15%);
  --surface3-dark: hsl(var(--brand-hue) 5% 20%);
  --surface4-dark: hsl(var(--brand-hue) 5% 25%);
  --surface5-dark: hsl(var(--brand-hue) 5% 30%);

  --surface-shadow-dark: var(--brand-hue) 50% 3%;
  --shadow-strength-dark: 0.8;
  --background-dark: hsl(var(--brand-hue) 10% 10%);

  --warning-dark: hsl(0, 92%, 75%); /* Красный */
  --warning-dark-hover: hsl(0, 100%, 50%);
  --success-dark: hsl(77, 100%, 35%); /* Зеленый */
  --success-dark-hover: hsl(120, 100%, 35%);
  --attention-dark: hsl(60, 100%, 60%); /* Желтый */
  --attention-dark-hover: hsl(60, 100%, 50%);

  /* dim */
  --brand-dim: hsl(
    var(--brand-hue) calc(var(--brand-saturation) / 1.25)
      calc(var(--brand-lightness) / 1.25)
  );
  --text1-dim: hsl(var(--brand-hue) 15% 75%);
  --text2-dim: hsl(var(--brand-hue) 10% 61%);

  --text-for-translate-dim: hsl(60, 100%, 55%); /* Пример для dim */
  --text-grammar-dim: hsl(120, 100%, 30%); /* Зеленый */
  --text-is-translate-dim: hsl(
    var(--brand-hue) calc(var(--brand-saturation) / 1.25)
      calc(var(--brand-lightness) / 1.2)
  );

  --surface1-dim: hsl(var(--brand-hue) 10% 20%);
  --surface2-dim: hsl(var(--brand-hue) 10% 25%);
  --surface3-dim: hsl(var(--brand-hue) 5% 30%);
  --surface4-dim: hsl(var(--brand-hue) 5% 35%);
  --surface-shadow-dim: var(--brand-hue) 30% 13%;
  --shadow-strength-dim: 0.2;

  --warning-dim: hsl(0, 100%, 55%); /* Красный */
  --warning-dim-hover: hsl(0, 100%, 45%);
  --success-dim: hsl(120, 100%, 35%); /* Зеленый */
  --success-dim-hover: hsl(120, 100%, 30%);
  --attention-dim: hsl(60, 100%, 55%); /* Желтый */
  --attention-dim-hover: hsl(60, 100%, 45%);
}

:root {
  color-scheme: light;

  /* set defaults */
  --brand: var(--brand-dark);
  --brand2: var(--brand-light);

  --text1: var(--text1-light);
  --text2: var(--text2-light);
  --text3: var(--text3-light);
  --text-yellow: var(--text-yellow-light);

  --text-for-translate: var(--text-for-translate-light);
  --text-grammar: var(--text-grammar-light);
  --text-is-translate: var(--text-is-translate-light);

  --surface1: var(--surface1-light);
  --surface2: var(--surface2-light);
  --surface3: var(--surface3-light);
  --surface4: var(--surface4-light);
  --surface5: var(--surface5-light);

  --surface-shadow: var(--surface-shadow-light);
  --shadow-strength: var(--shadow-strength-light);
  --background: var(--background-light);

  --warning: var(--warning-light);
  --warning-hover: var(--warning-light-hover);
  --success: var(--success-light);
  --success-hover: var(--success-light-hover);
  --attention: var(--attention-light);
  --attention-hover: var(--attention-light-hover);
}

@media (prefers-color-scheme: dark) {
  :root {
    color-scheme: dark;

    --brand: var(--brand-light);
    --brand2: var(--brand-dark);

    --text1: var(--text1-dark);
    --text2: var(--text2-dark);
    --text3: var(--text3-dark);
    --text-yellow: var(--text-yellow-dark);

    --text-for-translate: var(--text-for-translate-dark);
    --text-grammar: var(--text-grammar-dark);
    --text-is-translate: var(--text-is-translate-dark);

    --surface1: var(--surface1-dark);
    --surface2: var(--surface2-dark);
    --surface3: var(--surface3-dark);
    --surface4: var(--surface4-dark);
    --surface5: var(--surface5-dark);

    --surface-shadow: var(--surface-shadow-dark);
    --shadow-strength: var(--shadow-strength-dark);
    --background: var(--background-dark);

    --warning: var(--warning-dark);
    --warning-hover: var(--warning-dark-hover);
    --success: var(--success-dark);
    --success-hover: var(--success-dark-hover);
    --attention: var(--attention-dark);
    --attention-hover: var(--attention-dark-hover);
  }
}

[color-scheme='light'] {
  color-scheme: light;

  --brand: var(--brand-light);
  --brand2: var(--brand-dark);

  --text1: var(--text1-light);
  --text2: var(--text2-light);
  --text3: var(--text3-light);
  --text-yellow: var(--text-yellow-light);

  --text-for-translate: var(--text-for-translate-light);
  --text-grammar: var(--text-grammar-light);
  --text-is-translate: var(--text-is-translate-light);

  --surface1: var(--surface1-light);
  --surface2: var(--surface2-light);
  --surface3: var(--surface3-light);
  --surface4: var(--surface4-light);
  --surface5: var(--surface5-light);

  --surface-shadow: var(--surface-shadow-light);
  --shadow-strength: var(--shadow-strength-light);
  --background: var(--background-light);

  --warning: var(--warning-light);
  --warning-hover: var(--warning-light-hover);
  --success: var(--success-light);
  --success-hover: var(--success-light-hover);
  --attention: var(--attention-light);
  --attention-hover: var(--attention-light-hover);
}

[color-scheme='dark'] {
  color-scheme: dark;

  --brand: var(--brand-dark);
  --text1: var(--text1-dark);
  --text2: var(--text2-dark);
  --text3: var(--text3-dark);
  --text-yellow: var(--text-yellow-dark);

  --text-for-translate: var(--text-for-translate-dark);
  --text-grammar: var(--text-grammar-dark);
  --text-is-translate: var(--text-is-translate-dark);

  --surface1: var(--surface1-dark);
  --surface2: var(--surface2-dark);
  --surface3: var(--surface3-dark);
  --surface4: var(--surface4-dark);
  --surface5: var(--surface5-dark);

  --surface-shadow: var(--surface-shadow-dark);
  --shadow-strength: var(--shadow-strength-dark);
  --background: var(--background-dark);

  --warning: var(--warning-dark);
  --warning-hover: var(--warning-dark-hover);
  --success: var(--success-dark);
  --success-hover: var(--success-dark-hover);
  --attention: var(--attention-dark);
  --attention-hover: var(--attention-dark-hover);
}

[color-scheme='dim'] {
  color-scheme: dark;

  --brand: var(--brand-dim);
  --text1: var(--text1-dim);
  --text2: var(--text2-dim);

  --text-for-translate: var(--text-for-translate-dim);
  --text-grammar: var(--text-grammar-dim);
  --text-is-translate: var(--text-is-translate-dim);

  --surface1: var(--surface1-dim);
  --surface2: var(--surface2-dim);
  --surface3: var(--surface3-dim);
  --surface4: var(--surface4-dim);
  --background: var(--background-dark);

  --surface-shadow: var(--surface-shadow-dim);
  --shadow-strength: var(--shadow-strength-dim);

  --warning: var(--warning-dim);
  --warning-hover: var(--warning-dim-hover);
  --success: var(--success-dim);
  --success-hover: var(--success-dim-hover);
  --attention: var(--attention-dim);
  --attention-hover: var(--attention-dim-hover);
}

:root {
  --backdrop-blur: blur(3px);
  --drop-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.15);
  --gutter: 40px;
  --pad: 16px;
  --pad-l: 24px;
  --pad-s: 8px;
  --pad-xs: 4px;
  --radius: 6px;
  --touch-target: 44px;
  --transition: all 0.2s cubic-bezier(0, 0, 0.5, 1);
}

* {
  box-sizing: border-box;
  margin: 0;
  font: inherit;
  color: inherit;
}

*:focus:not(.focus-visible) {
  outline: 0;
}

html {
  height: 100%;
  background-color: var(--background);
  color: var(--text1);
  font-family: 'Inter', 'Helvetica Neue', sans-serif;
  overflow: hidden;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: var(--background);
  color: var(--c-text);
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  font-size: 16px;
  line-height: 24px;
  margin: 0;
  overscroll-behavior: none;
  height: 100%;
}

h1,
.h1 {
  color: var(--text1);
  font-size: 32px;
  line-height: 40px;
  font-family: Georgia;
  font-weight: 400;
}

h1:first-child,
.h1:first-child {
  margin-bottom: 0;
}
h1:first-child + p,
.h1:first-child + p,
h1:first-child + .text,
.h1:first-child + .text {
  margin-top: 8px;
}

.text--noMargin {
  margin: 0;
}

.text--s {
  font-size: 13px;
  line-height: 16px;
}

.text--light {
  color: var(--c-text-light);
}

.text--center {
  text-align: center;
}

.text--trailing {
  text-align: right;
}

.footer-link {
  color: var(--brand2);
  text-decoration: none;
  /* text-decoration-line: underline;
    text-underline-offset: 4px;
    text-decoration-color: var(--surface4); */
  border-bottom: 1px dotted transparent;
}

.footer-link:hover {
  text-decoration-line: none;
  color: var(--brand2);
  text-decoration-color: var(--surface4);
  border-bottom: 1px dotted var(--brand2);
}

.footer-link:focus {
  color: var(--brand2);
  text-decoration-color: var(--surface4);
  border-bottom: 1px dotted var(--brand2);
  text-decoration-line: none;
}

.footer-link:focus-visible {
  color: var(--brand);
  text-decoration-line: none;
  text-underline-offset: 3px;
  text-decoration-color: var(--surface4);
}

/* .h1 {
    font-family: Georgia, Times, 'Times New Roman', serif;
    color: var(--text1);
    font-size: 32px;
    line-height: 40px;
    font-weight: 400;
  } */

.paragraph {
  color: var(--text1);
  font-size: 18px;
  line-height: 32px;
  font-weight: 400;
  /* padding-bottom: 16px; */
}

.optionsText {
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
}

.app {
  height: 100dvh;
  width: 100vw;
  display: flex;
  flex-direction: row;
  overflow-y: hidden;
}

.btnFill {
  width: 100%;
}

.wrapper {
  height: 100%;
  max-width: 1240px;
  display: flex;
  flex-direction: row;
  gap: 50px;
  padding: 16px 32px 0px 32px;
}

.content {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* overflow-y: scroll; */
}

.showPlaceholder {
  display: block;
}

.hidePlaceholder {
  display: none;
}

.options {
  position: block;
  height: auto !important;
  width: 100%;
  min-width: 400px;
  max-width: 500px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  overflow-y: scroll;
  padding-right: 32px;
  padding-bottom: 32px;
}

.showOptions {
  display: flex;
}

.hideOptions {
  display: none;
}

.scroll {
  overflow-y: scroll;
}

.nonScroll {
  overflow-y: visible;
}

.menuBtn {
  padding: 4px 4px;
}

.showMenuBtn {
  display: absolute;
  z-index: 10;
}

.hideMenuBtn {
  display: none;
}

.sideBarItem {
  height: 32px;
  width: 100%;
  display: flex;
  flex-direction: row;
  /* justify-content: center; */
  align-items: center;
  padding: 4px 8px;
}

@media only screen and (max-width: 1360px) {
}

@media (min-width: 1300px) {
  #storybook-root,
  :root {
    --gutter: 74px;
  }
}

@media only screen and (max-width: 1100px) {
}

@media only screen and (max-width: 664px) {
  .wrapper {
    gap: 0px;
  }

  h1,
  .h1 {
    color: var(--text1);
    font-size: 24px;
    line-height: 32px;
    font-family: Georgia;
    font-weight: 400;
  }

  .paragraph {
    color: var(--text1);
    font-size: 16px;
    line-height: 28px;
    font-weight: 400;
    padding-bottom: 8px;
  }
}
