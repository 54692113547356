.wrapper {
  margin-left: -12px;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;
}

.and {
  color: var(--text2);
  font-weight: 300;
}
